
.App {
  text-align: center;
  width: 95%;
}

a:visited {
  color: yellow;
}

#root {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ppvideo {
  width: 90%;
  border-radius: 25px;
  border: white 5px solid;
  margin: 10px;
}
.ppvideo-container {
  width: 100%;
}
.ppvideo-toplevel {
  width: 100%;
}
.andrea-photo {
  float: right;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  border: white 5px solid;
  margin: 10px;
}